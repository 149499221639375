import React from "react";
import Seo from "../components/SEO/SEO";
import Container from "../components/Container/Container";
import Helmet from "react-helmet";
import InView from "../components/InView";
import PortableText from "react-portable-text";
import { graphql, Link } from "gatsby";

export const query = graphql`
  query PrivacyPageQuery {
    sanityPrivacyAndDisclaimer {
      title
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Privacy = (props) => {
  const { data } = props;

  return (
    <>
      <Seo title={data.sanityPrivacyAndDisclaimer.title} />
      <Helmet
        bodyAttributes={{
          class: "no-banner",
        }}
      />
      <Container>
        <div className="border-b border-grey-default box-border">
          <InView>
            <div className="container flex md:grid md:grid-cols-12 mt-[88px]">
              <div className="md:col-span-9 xl:col-span-7 pb-10 md:pb-[120px]">
                <article className="copy md:pr-10">
                  <h1 className="font-serif text-[32px] leading-[1.2]">
                    {data.sanityPrivacyAndDisclaimer.title}
                  </h1>
                  <div className="copy copy--container lg:max-w-[80%]">
                    {data.sanityPrivacyAndDisclaimer._rawBody && (
                      <PortableText
                        content={data.sanityPrivacyAndDisclaimer._rawBody}
                        serializers={{
                          internalLink: ({ reference, children }) => {
                            const href = `/${reference._type}/${reference.slug.current}`;
                            return <Link to={href}>{children}</Link>;
                          },
                          link: ({ href, blank, children }) => {
                            return (
                              <a
                                href={href}
                                target={blank ? "_blank" : "_self"}
                                rel={blank ? "noreferrer" : undefined}
                              >
                                {children}
                              </a>
                            );
                          },
                        }}
                      />
                    )}
                  </div>
                </article>
              </div>
            </div>
          </InView>
        </div>
      </Container>
    </>
  );
};

export default Privacy;
